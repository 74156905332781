import { showLoginOverlay } from "../cb/ui/loginOverlay"
import { modalAlert } from "../common/alerts"
import { setAnonymousCallback } from "../common/auth"
import { canVideoAutoplayInline } from "../common/canAutoPlay"
import { roomLoaded } from "../common/context"
import { scheduleLogPresence } from "../common/logPresence"
import { MobileRoot } from "../common/mobilelib/mobileRoot"
import { addPageAction } from "../common/newrelic"
import { scheduleSentimentSurvey, SentimentSurveyType } from "../common/sentimentSurvey"
import { BaseEntryPoint, exportEntryPoint } from "./base"
import { followingEvent } from "../common/follow"
import { goToHomepage } from "../common/windowUtils"
import type { IPageContext } from "../cb/interfaces/context"
import type { IFollowing } from "../common/follow"

export interface IMobileChatContext extends IPageContext {deniedMessage: string}

exportEntryPoint(class Page extends BaseEntryPoint {
    protected context: IMobileChatContext

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected parseContext(contextObj: Record<string, any>): void {
        super.parseContext(contextObj)
        this.context.deniedMessage = contextObj["denied_message"]
    }

    protected init(): void {
        super.init()
        super.initRoom()
    }

    protected initMobile(): void {
        if (this.context.deniedMessage) {
            addEventListener("pageshow", event => {
                modalAlert(this.context.deniedMessage, () => {
                    goToHomepage()
                })
            })
            return
        }
        setAnonymousCallback(() => {
            showLoginOverlay({ fromFeature: true })
        })
        if (Boolean(this.context.showNpsSentimentSurveyGroup)) {
            scheduleSentimentSurvey(true, SentimentSurveyType.NPS_GROUP)
        }
        const broadcaster = window.location.pathname.match(/\/([^\/]+)\/?$/)?.[1] ?? ""
        canVideoAutoplayInline().then((inlineAutoplaySupported) => {
            new MobileRoot(broadcaster, inlineAutoplaySupported)
        }).catch(() => {})

        roomLoaded.listen((context) => {
            gtag("event", "page_view", { "page_title": context.dossier.room })
            scheduleLogPresence(context.chatConnection, context.dossier.exploringHashTag)
            addPageAction("RoomLoaded", { "location": "mobile" })
        })

        // Update follow count text in mobile_menu.html
        // TODO (https://multimediallc.atlassian.net/browse/CBD-128) can be handled in MobilePushMenu
        // once it's enabled on more than just roomlist pages
        followingEvent.listen((event: IFollowing) => {
            const menuFollowLink = document.getElementById("follow_page_link")
            if (event.totalCount !== undefined && event.totalCount > 0) {
                if (menuFollowLink !== null) {
                    menuFollowLink.style.display = "block"
                }
                const onlineCountText = document.getElementById("follow_count_online")
                const totalCountText = document.getElementById("follow_count_total")
                if (onlineCountText !== null && event.onlineCount !== undefined) {
                    onlineCountText.textContent = `${event.onlineCount}`
                }
                if (totalCountText !== null && event.totalCount !== undefined) {
                    totalCountText.textContent = `${event.totalCount}`
                }
            } else {
                if (menuFollowLink !== null) {
                    menuFollowLink.style.display = "none"
                }
            }
        })

        super.initMobile()
    }

    protected bindDesktopRedirectLinkUpdates(anchor: HTMLAnchorElement): void {
        roomLoaded.listen(() => {
            anchor.href = window.location.pathname
        })
    }
})
